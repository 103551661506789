import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Markdown from "react-markdown"
import { FeaturedArticles, Hero } from "../containers"
import { TitleV2 } from "../components"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      link
      description
      content
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO]
              aspectRatio: 3.2
            )
          }
        }
      }
      author {
        name
        picture {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    strapiNewsPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
    allStrapiArticle(filter: { featured: { eq: true } }) {
      nodes {
        strapiId
        slug
        link
        title
        description
        date
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 300
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        author {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  height: 50
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
const Article = ({ data }) => {
  const {
    allStrapiArticle: { nodes: articles },
  } = data
  const article = data.strapiArticle
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  }

  const image = getImage(
    data.strapiNewsPage.image.localFile.childImageSharp.gatsbyImageData
  )

  //console.log("Template - Articles - data:", data)

  return (
    <div seo={seo}>
      <Hero image={image} title={article.title} />

      <section className="uk-section uk-section-small ">
        <div id="author-wrap" className="uk-container">
          <hr className="uk-divider-icon" />
          <div
            className="uk-grid uk-grid-medium uk-flex uk-flex-middle"
            uk-grid="true"
          >
            <div className="uk-width-auto">
              {article.author.picture && (
                <GatsbyImage
                  alt="alt text"
                  image={getImage(
                    article.author.picture.localFile.childImageSharp
                      .gatsbyImageData
                  )}
                  className="uk-border-circle"
                />
              )}
            </div>

            <div className="uk-width-expand">
              <h4 className="uk-margin-remove uk-text-bold">
                By {article.author.name}
              </h4>
              <span className="uk-text-small uk-text-muted">
                <Moment format="MMM Do YYYY">{article.published_at}</Moment>
              </span>
            </div>

            <div className="uk-width-auto">
              <div className="uk-inline">
                <a
                  href="/"
                  className="uk-icon-button"
                  aria-label="Icon verticle"
                  uk-icon="icon:more-vertical"
                >
                  {null}
                </a>
                <div uk-dropdown="mode:hover; pos: bottom-right; boundary:#author-wrap">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li className="uk-nav-header">Author</li>
                    <li>
                      <a href="/">Rate this author</a>
                    </li>
                    <li>
                      <a href="/">Follow this author</a>
                    </li>
                    <li>
                      <a href="/">Bookmark</a>
                    </li>
                    <li>
                      <a href="/articles">View more articles</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="uk-divider-icon" />
        </div>
      </section>

      <div className="uk-section uk-padding-remove-top uk-padding-remove-bottom">
        <div className="uk-container uk-margin-large-bottom">
          <TitleV2 title={article.title} />

          <Markdown children={article.content} />

          <hr className="uk-divider-small" />
        </div>
        <FeaturedArticles
          articles={articles}
          title="More Articles"
          styleClass="underlineOrange"
        />
      </div>
    </div>
  )
}
export default Article
